import React from 'react';
import CategoryHeading from '@/components/CategoryHeading';
import { getPageIconByCategory } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';
import { GameContentProps } from '../types';
import { Heading, Text } from '@purposeinplay/core-v2';
import { TextLoader } from '@/components/Loaders';
import config from '@/config';
import { usePathname } from 'next/navigation';
import Image from 'next/image';

const GamesPageHeading: React.FC<{
  title?: string;
  gameCategory?: string;
  className?: string;
  totalCount?: number | null;
  isTotalCountLoading?: boolean;
}> = ({
  gameCategory = 'all-games',
  totalCount,
  className,
  title,
  isTotalCountLoading,
}) => {
  const { t } = useTranslation();
  const gameCategoryContent = t('game_category') as GameContentProps;
  const pathname = usePathname();

  const pageHeadingTitleFallback = pathname
    .replace('/categories/', '')
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');

  const titleOrCategory =
    title ||
    (gameCategory &&
      gameCategoryContent?.titles[
        gameCategory === 'all-games' ? 'games' : gameCategory
      ]);

  const pageHeading = titleOrCategory || pageHeadingTitleFallback;

  return (
    <CategoryHeading className="!mb-0 w-full">
      {!title && (
        <div className="mr-2 shrink-0 rounded-md bg-transparent">
          {/* if it is for themes (all collections) */}
          {config?.SHOWN_WILD_COLLECTIONS?.includes(gameCategory) ? (
            <Image
              alt={`image ${gameCategory}`}
              height={24}
              width={24}
              src={`/assets/home-category-icons/${gameCategory}.svg`}
            />
          ) : (
            getPageIconByCategory(
              gameCategory && gameCategory === 'new'
                ? 'fresh-releases'
                : gameCategory
                  ? gameCategory
                  : 'all-games',
              24,
              24,
            )
          )}
        </div>
      )}
      <div>
        <Heading
          as="h1"
          className="inline text-xl font-semibold capitalize leading-7 text-text-default"
        >
          {pageHeading.replace(/-/g, ' ')}
        </Heading>
        {isTotalCountLoading ? (
          <TextLoader className="h-4 w-20 rounded-full lg:w-20" />
        ) : (
          <Text size="xs" className="text-text-subdued">
            {totalCount} Games
          </Text>
        )}
      </div>
    </CategoryHeading>
  );
};

export default GamesPageHeading;
