import React from 'react';

import Timeleft from '@/components/Timeleft';
import useBonusTime from '@/hooks/use-bonus-time';
import useCountdown from '@/hooks/use-countdown';

const BonusDetailsTimerBanner = () => {
  const { time } = useBonusTime();

  const { state } = useCountdown({
    short: false,
    date: time as string,
    enabled: time !== null,
  });
  return (
    <Timeleft
      stylingOptions={{
        unit: 'bg-bgr-lightest text-text-default w-7 h-7 text-xs',
        dot: '-mt-1',
      }}
      className="justify-start"
      extraSmall
      small
      invert={true}
      units={{
        days: state?.days,
        hours: state?.hours,
        minutes: state?.minutes,
        seconds: state?.seconds,
      }}
    />
  );
};

export default BonusDetailsTimerBanner;
