import { TransactionProps } from '../../../types';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';

export const useCalculateTransactionType = (transaction: TransactionProps) => {
  const walletContent = useGetWalletContent();
  if (!transaction) {
    return {
      tag: 'success',
      type: walletContent?.transactions?.types?.completed,
    };
  }

  const type =
    transaction.action === 'deposit'
      ? 'deposit'
      : !transaction.action
        ? 'bet'
        : 'cashout';

  if (type === 'bet') {
    return { tag: 'success', type: 'Completed' };
  }

  if (transaction?.recallable && transaction?.state === 'reserved') {
    return {
      tag: 'warning',
      type: walletContent?.transactions?.types?.pending,
    };
  }

  if (
    !transaction?.recallable &&
    transaction?.state === 'recalled' &&
    !transaction?.success
  ) {
    return {
      tag: 'highlight',
      type: walletContent?.transactions?.types?.cancelled,
    };
  }

  if (
    !transaction?.recallable &&
    transaction?.state === 'rejected' &&
    !transaction?.success
  ) {
    return {
      tag: 'highlight',
      type: walletContent?.transactions?.types?.rejected,
    };
  }

  if (!transaction?.recallable && transaction?.success) {
    return {
      tag: 'success',
      type: walletContent?.transactions?.types?.completed,
    };
  }

  return {
    tag: 'success',
    type: walletContent?.transactions?.types?.completed,
  };
};

export function formatNumberWithCommas(numberString: string) {
  if (typeof numberString !== 'string') {
    throw new Error('Input must be a string.');
  }

  const withoutDollarSign = numberString.replace(/\$/g, '');
  const [integerPart, decimalPart] = withoutDollarSign.split('.');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  );
  const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '';

  return `$${formattedIntegerPart}${formattedDecimalPart}`;
}

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  /** The type of badge */
  type?:
    | 'neutral'
    | 'error'
    | 'warning'
    | 'success'
    | 'information'
    | 'highlight'
    | 'purple';
  /** The size of the badge */
  size?: 'sm' | 'lg';
  /** The roundness of the badge */
  radius?: 'basic' | 'rounded';
  /** Any trailing element */
  trailingIcon?: React.ReactNode;
  /** Any leading element */
  leadingIcon?: React.ReactNode;
};

export const isValidBadgeType = (
  tag: string | undefined,
): tag is BadgeProps['type'] => {
  return (
    tag === undefined ||
    [
      'success',
      'warning',
      'highlight',
      'error',
      'neutral',
      'information',
      'purple',
    ].includes(tag)
  );
};

export const getTextColorByType = (type: string) => {
  switch (type) {
    case 'Completed':
      return 'text-base-primary';
    case 'Cancelled':
      return 'text-base-critical';
    case 'Rejected':
      return 'text-base-warning';
    default:
      return 'text-text-default';
  }
};
