import { Transition } from '@headlessui/react';
import { MobileDropdownProps } from './types';
import { IconButton } from '@purposeinplay/core-v2';
import SettingsImg from './components/settings-img';
import GamesPageHeading from '@/modules/category-page/components/games-page-heading';
import { cn } from '@/utils/style';

const MobileDropdown = ({
  withSearchBar,
  setOpen,
  title,
  open,
  children,
  className,
  transitionClassName,
  triggerClassName,
  collection = 'all-games',
  icon,
  ...props
}: MobileDropdownProps) => {
  return (
    <div className={cn(['relative z-30 lg:hidden', className])} {...props}>
      <div
        className={cn([
          'flex items-center justify-between pt-0.5',
          triggerClassName,
        ])}
      >
        {title ? (
          <span className="text-sm font-semibold">{title}</span>
        ) : (
          <GamesPageHeading
            className="[&>h2]:text-sm [&>h2]:font-semibold"
            gameCategory={collection}
          />
        )}

        <IconButton
          className="h-7 w-7"
          iconOnly
          iconLeft={icon ? icon : <SettingsImg />}
          color="tertiary"
          ghost
          onClick={() => setOpen(!open)}
        />
      </div>

      <Transition
        className={cn(['relative z-20 mt-3', transitionClassName])}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 "
        enterTo="opacity-100 "
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 "
        leaveTo="opacity-0 "
        show={open}
      >
        <div className="mb-1.5 flex h-full  space-x-1.5">{children}</div>
      </Transition>
    </div>
  );
};

export default MobileDropdown;
