import BlurryImage from '@/components/GameCard/blurry-image';
import { FALLBACK_SRC_BASE } from '@/components/GameCard/constants';
import React from 'react';

const GameThumbnails = ({ completeGames }: any) => {
  return (
    <div className="flex flex-row items-center">
      {completeGames?.map((game: any, index: number) => {
        const imgUrl = `https://cdn.wild.io/thumbnail/${game?.identifier}.png`;
        const fallbackUrl = `${FALLBACK_SRC_BASE}/thumbnail/${
          game?.identifier.split('/')[0]
        }/${game?.identifier.split('/')[1]}.webp`;
        return (
          <div
            key={game?.identifier}
            className="relative -ml-2 h-6 w-6 rounded-full border-2 border-bgr-lightest"
          >
            <BlurryImage
              src={imgUrl}
              setSrc={() => {}}
              fallBackUrl={fallbackUrl ?? ''}
              alt={`Game card image for ${game?.title}`}
            />
          </div>
        );
      })}
    </div>
  );
};
export default GameThumbnails;
