import { Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import React from 'react';

import { useTranslation } from '@/app/i18n/client';
import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import BonusDetailsTimerBanner from './bonus-details-timer-banner';

type Props = {
  title: string;
  className?: string;
  background?: string;
  isInWallet?: boolean;
};

const BonusDetailsBanner = ({
  title,
  className,
  background,
  isInWallet = false,
}: Props) => {
  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  return (
    <div
      data-theme="dark"
      className={cn([
        'relative flex flex-row items-center justify-center gap-spacing-xs rounded-lg p-3',
        background && background,
        className && className,
      ])}
    >
      <Image
        alt="Banner background details"
        src="/assets/coins.png"
        fill
        loader={DEFAULT_IMAGE_LOADER}
        className="absolute inset-0 object-contain"
      />
      <div>
        <Image
          alt="Bonus Prize Details Lightning"
          height={32}
          width={32}
          src="/assets/wallet/lightning.svg"
          loader={DEFAULT_IMAGE_LOADER}
        />
      </div>

      <div className="z-10 flex w-full flex-row items-center justify-between gap-spacing-sm md:gap-0">
        <div className="flex w-1/2 flex-col">
          <Text
            size="sm"
            className="font-medium md:text-base md:font-semibold md:leading-none"
          >
            {content?.bonus_selection_modal?.countdown_label}
          </Text>
          <Text size="lg" className="font-bold md:text-2xl md:leading-none">
            {title}
          </Text>
        </div>
        {!isInWallet && <BonusDetailsTimerBanner />}
      </div>
    </div>
  );
};

export default BonusDetailsBanner;
