import React, { useMemo } from 'react';
import { TextLoader } from '@/components/Loaders';
import { getIconByCurrency } from '@/utils/helpers';
import { Account } from '@/types/accounts';
import { cn } from '@/utils/style';

type Props = {
  balance: string | number;
  account?: Account;
  currency?: string;
  removeIcon?: boolean;
  className?: string;
};

const CryptoAmount: React.FC<Props> = ({
  balance,
  account,
  currency,
  removeIcon,
  className,
}) => {
  const balanceWithoutCrypto = useMemo(() => {
    if (typeof balance === 'number') {
      return balance.toString();
    }
    return balance;
  }, [balance]);

  const getCurrency = useMemo(() => {
    if (account) {
      return account.currency;
    }
    return currency;
  }, [account, currency]);

  if (balance === undefined) {
    return <TextLoader />;
  }

  return (
    <span
      className={cn([
        'flex items-center truncate text-sm leading-4',
        className,
      ])}
    >
      <span className={cn(['mr-1 truncate', removeIcon && 'mr-0'])}>
        {balanceWithoutCrypto}
      </span>
      {!removeIcon && (
        <span className="shrink-0">
          {getIconByCurrency(getCurrency?.toLowerCase() ?? 'BTC')}
        </span>
      )}
    </span>
  );
};

export default CryptoAmount;
