import CryptoTooltip from '@/components/CryptoTooltip';
import { useFiatFromCents } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import CryptoAmount from '@/components/CryptoAmount';
import { useAccounts } from '@/hooks/accounts';
import { useCurrencyObj } from '@/hooks/currencies';

const MaxBonus = React.memo(function MaxBonus({
  data,
  isBonusWheel,
  forList = false,
}: {
  data: any;
  forList?: boolean;
  isBonusWheel?: boolean;
}) {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const { account } = useAccounts();
  const fiatMaxBonus = useFiatFromCents(
    account?.currency,
    isBonusWheel ? data?.max_reward : data?.maxBonus,
  );
  const walletContent = useGetWalletContent();

  const { formatCurrency } = useCurrencyFormatter();

  return (
    <div
      className={cn([
        forList &&
          'flex items-end justify-end [&>p>div>span>span]:text-sm [&>p>div>span>span]:leading-4 [&>p>div>span>span]:text-text-default',
      ])}
    >
      <Text
        size="xs"
        className="flex flex-row space-x-1 leading-4 text-text-subdued"
      >
        {!forList && (
          <span>
            {walletContent?.deposit?.bonuses?.detail_modal?.max_bonus}
          </span>
        )}
        <CryptoTooltip
          cryptoAmount={
            !viewFiat ? (
              <CryptoAmount
                removeIcon
                balance={
                  isBonusWheel
                    ? formatCurrency(data?.max_reward, account?.currency, {
                        style: 'decimal',
                      })
                    : data?.maxBonus
                }
                currency={account?.currency?.toLowerCase()}
              />
            ) : (
              <Text size="sm" className="leading-4">
                {fiatMaxBonus}
              </Text>
            )
          }
          cryptoCode={account?.currency}
        >
          <CryptoAmount
            balance={
              isBonusWheel
                ? formatCurrency(data?.max_reward, account?.currency, {
                    style: 'decimal',
                  })
                : data?.maxBonus
            }
            currency={account?.currency}
          />
        </CryptoTooltip>
      </Text>
    </div>
  );
});

MaxBonus.displayName = 'MaxBonus';

export default MaxBonus;
