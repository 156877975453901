'use client';
import { PublicationState, useWofQuery } from '@/generated';
import { useAccounts } from '@/hooks/accounts';
import { useCurrencyObj } from '@/hooks/currencies';
import { useAppSelector } from '@/hooks/redux';
import { useGetFoundCurrencyDeposit } from '@/hooks/use-get-found-currency-deposit';
import {
  PreviewBonus,
  PreviewBonusAttribute,
  PreviewBonusCondition,
} from '@/types/bonuses';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { useParams, useSearchParams } from 'next/navigation';

import { useMemo } from 'react';

export const useGetPreviewBonusData = (
  bonus: PreviewBonus,
  rawOutput?: boolean,
) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const { account } = useAccounts();
  const searchParams = useSearchParams();
  const paramCurrency = searchParams.get('c');

  const currency = useCurrencyObj(paramCurrency || account?.currency);

  const { formatCurrency } = useCurrencyFormatter();
  const { data: wofBonus, isPending: pendingWof } = useWofQuery({
    title: bonus?.title || '',
  });

  const wofData = useMemo(() => {
    if (pendingWof) return undefined;
    return wofBonus?.wheelOfFortunes?.data[0]?.attributes;
  }, [pendingWof, wofBonus?.wheelOfFortunes?.data]);

  const isBonusWheel = wofData !== undefined && !pendingWof;
  const data = useMemo(() => {
    const freespinsCount = bonus?.attributes?.find(
      (att: PreviewBonusAttribute) => att.field === 'freespins_count',
    );
    const cycles = bonus?.attributes?.find(
      (att: PreviewBonusAttribute) => att.field === 'cycles',
    );
    const freespinsWager = bonus?.result_bonus?.find(
      (att: PreviewBonusAttribute) => att.field === 'wager',
    );
    const freespinsGames = bonus?.attributes?.find(
      (att: PreviewBonusAttribute) => att.field === 'games',
    );
    const wagerPercentage = bonus?.attributes?.find(
      (att: PreviewBonusAttribute) => att.field === 'bonus_amount',
    );
    const maxBonus = bonus?.attributes
      .find(
        (att: PreviewBonusAttribute) =>
          att.field === 'bonus_amount' && att.type === 'max',
      )
      ?.value.find((item: any) => item.currency === currency?.code, {
        style: 'decimal',
      });

    const minDepositSum = bonus?.conditions
      .find(
        (att: PreviewBonusCondition) =>
          att.field === 'amount' && att.type === 'min',
      )
      ?.value.find((item: any) => item.currency === currency?.code, {
        style: 'decimal',
      });
    const wager = bonus?.attributes?.find(
      (att: PreviewBonusAttribute) => att.field === 'wager',
    );

    return {
      freespinsWager,
      freespinsCount: !cycles
        ? freespinsCount
        : { value: freespinsCount?.value * cycles.value },
      freespinsGames: freespinsGames?.value.map(
        (game: any) => game.split('/')[1],
      ),
      wager,
      wagerPercentage,
      maxBonus: viewFiat
        ? maxBonus?.amount_cents
        : formatCurrency(maxBonus?.amount_cents, currency?.code, {
            style: 'decimal',
          }),
      minDepositSum: viewFiat
        ? minDepositSum?.amount_cents
        : formatCurrency(minDepositSum?.amount_cents, currency?.code, {
            style: 'decimal',
          }),
    };
  }, [
    bonus?.attributes,
    bonus?.conditions,
    bonus?.result_bonus,
    currency?.code,
    formatCurrency,
    viewFiat,
  ]);

  const dataBonusWheel = useMemo(() => {
    if (!isBonusWheel || pendingWof) return;
    const dataContent = wofData?.preview_bonus;
    const min_deposit_value = dataContent?.min_deposit_values?.find(
      (item: any) => item.currency === currency?.code,
    )?.amount_cents;
    const max_reward_value = dataContent?.max_reward_values?.find(
      (item: any) => item.currency === currency?.code,
    )?.amount_cents;

    return {
      wager: dataContent?.wager,
      min_deposit: !min_deposit_value
        ? '0'
        : rawOutput
          ? min_deposit_value
          : viewFiat
            ? min_deposit_value
            : formatCurrency(Number(min_deposit_value), currency?.code, {
                style: 'decimal',
              }),
      max_reward: !max_reward_value
        ? '0'
        : rawOutput
          ? max_reward_value
          : viewFiat
            ? max_reward_value
            : formatCurrency(Number(max_reward_value), currency?.code, {
                style: 'decimal',
              }),
    };
  }, [
    isBonusWheel,
    pendingWof,
    wofData?.preview_bonus,
    rawOutput,
    viewFiat,
    formatCurrency,
    currency?.code,
  ]);

  return { isBonusWheel, data, dataBonusWheel };
};
