import GameItem from '@/components/Games/game-item';
import { cn } from '@/utils/style';
import { Modal } from '@purposeinplay/core-v2';
import React from 'react';

const GamesModal = ({
  bonus,
  content,
  completeGames,
  open,
  setOpen,
  isPreview,
  title,
}: {
  bonus: any;
  content?: any;
  completeGames: any;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  isPreview?: boolean;
  title?: string;
}) => {
  return (
    <Modal
      isOpen={open}
      setIsOpen={setOpen}
      title={!title ? content?.games : title}
    >
      {(bonus?.type === 'freespins' || isPreview) && (
        <div>
          <div className="relative">
            <div
              className={cn([
                'scrollbar relative',
                completeGames?.length > 4 && 'max-h-[10rem]',
              ])}
            >
              <ul className="mb-5 grid grid-cols-3 gap-2.5 last:mb-0 ">
                {completeGames?.map((game: any, index: number) => {
                  return <GameItem key={index} game={game} />;
                })}
              </ul>
            </div>
            {completeGames?.length > 4 && (
              <span className="pointer-events-none absolute bottom-0 h-10 w-full bg-gradient-to-t from-bgr-lighter to-transparent" />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default GamesModal;
