import CryptoTooltip from '@/components/CryptoTooltip';
import { useFiatFromCents } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import React from 'react';
import { formatNumberWithCommas } from '../../Transactions/utils';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { cn } from '@/utils/style';
import CryptoAmount from '@/components/CryptoAmount';
import { Text } from '@purposeinplay/core-v2';
import { useAccounts } from '@/hooks/accounts';
import { useSearchParams } from 'next/navigation';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';

const MinDeposit = React.memo(
  ({
    data,
    short = false,
    isBonusWheel,
    forList = false,
  }: {
    data: any;
    isBonusWheel?: boolean;
    short?: any;
    forList?: boolean;
  }) => {
    const { account } = useAccounts();
    const searchParams = useSearchParams();
    const paramCurrency = searchParams.get('c');
    const currency = paramCurrency || account?.currency;

    const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
    const fiatMinDepositSum = useFiatFromCents(
      currency,
      isBonusWheel ? data?.min_deposit : data?.minDepositSum,
    );

    const walletContent = useGetWalletContent();
    const { formatCurrency } = useCurrencyFormatter();

    return (
      <div
        className={cn([
          'flex flex-row space-x-1 text-xs text-text-subdued',
          short && 'flex-wrap gap-x-1 space-x-0',
          forList &&
            'flex justify-end [&>div>span>span>span]:text-sm [&>div>span>span>span]:leading-4 [&>div>span>span>span]:text-text-default',
        ])}
      >
        {!forList && (
          <span>
            {short
              ? `${walletContent?.tabs[0].label}:`
              : walletContent?.deposit?.bonuses?.min_deposit}
          </span>
        )}
        <CryptoTooltip
          cryptoAmount={
            <>
              {' '}
              <span className="whitespace-nowrap">
                {!viewFiat ? (
                  <CryptoAmount
                    removeIcon
                    balance={
                      isBonusWheel
                        ? formatCurrency(data?.min_deposit, currency, {
                            style: 'decimal',
                          })
                        : data?.minDepositSum
                    }
                    currency={currency?.toLowerCase()}
                  />
                ) : typeof fiatMinDepositSum === 'string' ? (
                  forList ? (
                    <Text size="sm">
                      {formatNumberWithCommas(fiatMinDepositSum)}
                    </Text>
                  ) : (
                    formatNumberWithCommas(fiatMinDepositSum)
                  )
                ) : forList ? (
                  <Text size="sm">{fiatMinDepositSum}</Text>
                ) : (
                  fiatMinDepositSum
                )}
              </span>
            </>
          }
          cryptoCode={currency}
        >
          <CryptoAmount
            balance={
              isBonusWheel
                ? formatCurrency(data?.min_deposit, currency, {
                    style: 'decimal',
                  })
                : formatCurrency(data?.minDepositSum, currency, {
                    style: 'decimal',
                  })
            }
            currency={currency}
          />
        </CryptoTooltip>
      </div>
    );
  },
);

MinDeposit.displayName = 'MinDeposit';

export default MinDeposit;
