import { PreviewBonus, PreviewBonusAttribute } from '@/types/bonuses';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { useGetPreviewBonusData } from '../hooks';
import { ListComponent, Text } from '@purposeinplay/core-v2';
import MaxBonus from './max-bonus';
import MinDeposit from './min-deposit';
import GameThumbnails from '@/components/Bonuses/components/game-thumnails';
import GamesModal from '@/components/Bonuses/components/bonus-details-modal/games-modal';
import { useState } from 'react';
import { useGamesByIds } from '@/hooks/games/filters';
import BonusDetailsBanner from '@/components/Bonuses/components/bonus-preview/bonus-details-banner';
import { getBackgroundByType } from '@/components/Bonuses/utils';

export const BonusDetailItem = ({
  bonus,
  isInWallet,
}: {
  key?: string;
  bonus: PreviewBonus;
  isInWallet?: boolean;
}) => {
  const walletContent = useGetWalletContent();

  const [gamesModalOpen, setGamesModalOpen] = useState(false);

  const detailContent = walletContent?.deposit?.bonuses?.detail_modal;

  const { isBonusWheel, data, dataBonusWheel } = useGetPreviewBonusData(
    bonus,
    true,
  );

  const games = bonus?.attributes?.find(
    (att: PreviewBonusAttribute) => att.field === 'games',
  );

  const { data: completeGames } = useGamesByIds(
    {
      game_ids: games?.value,
    },
    {
      enabled: games?.value && games?.value?.length > 0,
    },
  );

  return (
    <div
      key={bonus?.title}
      className="mb-1 font-semibold text-text-default last:mb-0"
    >
      {!isInWallet && <div className="mb-1">{bonus?.title}</div>}
      {isInWallet && (
        <BonusDetailsBanner
          title={bonus?.title || ''}
          background={getBackgroundByType('general')}
          isInWallet={isInWallet}
          className="mb-7"
        />
      )}
      <ListComponent
        stylingOptions={{
          containerStyles: 'pointer-events-none hover:bg-surface-subdued mb-1',
        }}
        label={
          <Text size="sm" className="leading-4">
            {detailContent?.wager}
          </Text>
        }
        value={
          <Text size="sm" className="text-right leading-4">
            {isBonusWheel
              ? dataBonusWheel?.wager
              : data.freespinsWager
                ? data.freespinsWager.value
                : data?.wager?.value}
            {!isBonusWheel && 'x'}
          </Text>
        }
      />
      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued mb-1 !cursor-auto',
        }}
        label={
          <Text size="sm" className="leading-4">
            {data?.freespinsGames ? (
              detailContent?.games
            ) : (
              <div>{detailContent?.max_bonus}</div>
            )}
          </Text>
        }
        value={
          <div className="flex justify-end">
            {games ? (
              <div
                className="ml-auto flex cursor-pointer flex-row items-center space-x-spacing-xs"
                onClick={() => {
                  setGamesModalOpen(true);
                }}
              >
                <GameThumbnails completeGames={completeGames?.slice(0, 3)} />
                {completeGames?.length > 3 && (
                  <Text size="xs" className="text-base-primary">
                    + {completeGames?.length - 3} More
                  </Text>
                )}
              </div>
            ) : (
              <MaxBonus
                isBonusWheel={isBonusWheel}
                data={isBonusWheel ? dataBonusWheel : data}
                forList
              />
            )}
          </div>
        }
      />
      <ListComponent
        stylingOptions={{
          containerStyles: '!cursor-default hover:bg-surface-subdued mb-1',
        }}
        label={detailContent?.min_deposit}
        value={
          <MinDeposit
            forList
            isBonusWheel={isBonusWheel}
            data={isBonusWheel ? dataBonusWheel : data}
          />
        }
      />
      <GamesModal
        bonus={bonus}
        open={gamesModalOpen}
        setOpen={setGamesModalOpen}
        title="Available Games"
        completeGames={completeGames}
      />
    </div>
  );
};
