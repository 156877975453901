import { cn } from '@/utils/style';
import React from 'react';

const CategoryHeading = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => {
  return (
    <div className={cn(['mb-4 flex items-center', className])}>{children}</div>
  );
};

export default CategoryHeading;
