import React from 'react';
import useCountdown from '@/hooks/use-countdown';
import { Text } from '@purposeinplay/core-v2';
import ClockSvg from 'public/assets/clock.svg';
import { cn } from '@/utils/style';

interface Props {
  date: any;
  withSvg?: boolean;
  textStyle?: string;
  withoutTextTag?: boolean;
}

const Countdown = ({
  date,
  withSvg = true,
  textStyle,
  withoutTextTag = false,
}: Props) => {
  const { state } = useCountdown({
    short: false,
    date: date,
  });

  return (
    <div className="flex flex-row items-center gap-spacing-2xs">
      {withSvg && (
        <ClockSvg className="h-4 w-4 fill-current text-[#B7791F] dark:text-[#FAF088]" />
      )}
      {withoutTextTag ? (
        <span>
          {state?.days &&
            (state?.days !== undefined || state?.days !== 0
              ? state?.days + 'd '
              : '')}
          {state?.hours || '00'}h {state?.minutes || '00'}m{' '}
          {state?.seconds || '00'}s
        </span>
      ) : (
        <Text
          size="xs"
          className={cn([
            'text-[#B7791F] dark:text-[#FAF088]',
            textStyle && textStyle,
          ])}
        >
          {state?.days &&
            (state?.days !== undefined || state?.days !== 0
              ? state?.days + 'd '
              : '')}
          {state?.hours || '00'}h {state?.minutes || '00'}m{' '}
          {state?.seconds || '00'}s
        </Text>
      )}
    </div>
  );
};

export default Countdown;
