import React from 'react';

const SettingsImg = () => {
  return (
    <svg
      className="stroke-current text-text-default"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 8L3.33337 2.66667"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M12.6666 13.3335L12.6666 11.3335"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M3.33337 13.3335L3.33337 10.6668"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M12.6666 8.6665L12.6666 2.6665"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8 4.6665L8 2.6665"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8 13.3335L8 7.3335"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <ellipse
        cx="3.33333"
        cy="9.33333"
        rx="1.33333"
        ry="1.33333"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <ellipse
        cx="7.99996"
        cy="5.99984"
        rx="1.33333"
        ry="1.33333"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <ellipse
        cx="12.6667"
        cy="9.99984"
        rx="1.33333"
        ry="1.33333"
        stroke="current"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SettingsImg;
