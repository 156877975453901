import dayjs from 'dayjs';
import { useAuth } from './auth';
import { useMemo } from 'react';

function useBonusTime() {
  const { user } = useAuth();
  const { time, progress } = useMemo(() => {
    if (user?.isLoading || !user?.currentUser?.created_at) {
      return { time: null, progress: 0 };
    }
    const currentTime = dayjs()?.utc();
    const isoFormattedString =
      user?.currentUser?.created_at &&
      user?.currentUser?.created_at?.replace(' UTC', 'Z');

    // Calculate 48 hours from the created_at time
    const deadline = isoFormattedString
      ? dayjs(isoFormattedString).add(48, 'hour').utc()
      : null;

    if (!deadline) {
      return { time: null, progress: 0 };
    }

    // Check if the deadline has passed
    if (currentTime.isAfter(deadline)) {
      const startOfDay = dayjs(new Date()).startOf('day').utc().toString();
      const hoursPassedToday = currentTime.diff(startOfDay, 'hour');

      // Fake the deadline by subtracting the hours passed today
      const fakeDeadline = currentTime
        .add(48, 'hour')
        .subtract(hoursPassedToday, 'hour');
      const timeRemaining = fakeDeadline?.diff(currentTime, 'hour');
      const progress = timeRemaining ? ((48 - timeRemaining) / 48) * 100 : 100;

      return { time: fakeDeadline, progress: progress };
    } else {
      // If the deadline has not passed, return the actual deadline
      const timeRemaining = deadline?.diff(currentTime, 'hour');
      const progress = timeRemaining ? ((48 - timeRemaining) / 48) * 100 : 100;
      return { time: deadline.utc().toString(), progress: progress };
    }
  }, [user?.currentUser?.created_at, user?.isLoading]);
  return { time, progress };
}

export default useBonusTime;
