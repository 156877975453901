import { useTranslation } from '@/app/i18n/client';
import { WalletContentType } from '@/types/json-content/wallet';

export const useGetWalletContent = () => {
  const { t } = useTranslation();
  const walletContentModal = t('wallet') as WalletContentType;

  const walletContent = walletContentModal?.modal;

  return walletContent;
};

export const useGetWalletSettingsContent = () => {
  const { t } = useTranslation();
  const walletContentModal = t('wallet') as WalletContentType;

  const walletContent = walletContentModal?.settings_modal;

  return walletContent;
};
